import './scss/main.scss'

import $ from 'jquery';

import '@fortawesome/fontawesome-free/js/all.js'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import 'owl.carousel';

import "bootstrap";

$(".play").click(function(){
    $(".video .video").html('<iframe width="640" height="360" src="https://www.youtube.com/embed/Nszq6x1DEU0?autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
});